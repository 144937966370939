import { Component, OnInit } from '@angular/core';
import { Contact } from './contact';
import { ContactformService } from '../contactform.service';
import {MatDialog} from '@angular/material';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.css']
})
export class DefaultComponent implements OnInit {

  constructor(private contactFormService: ContactformService, public dialog: MatDialog) {}
  contactModel = new Contact('', '', '', '', '');
  submit = false;
  onSubmit() {
    this.submit = true;
  
    this.contactFormService.sendMessage(this.contactModel)
    .subscribe(
      data => console.log('success', data),
      err => console.error('error', err)
    );
    this.dialog.open(DialogComponent)
    
  }


  ngOnInit() {
  }


}

