import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Contact } from './default/contact';

@Injectable({
  providedIn: 'root'
})
export class ContactformService {

  url = 'https://us-central1-trial-7a169.cloudfunctions.net/sendMail';
  constructor(private http: HttpClient) { }
  sendMessage(contact: Contact){
    return this.http.post(this.url, contact);
  }

}
