import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultComponent } from './demos/default/default.component';
import { DemoTwoComponent } from './demos/demo-two/demo-two.component';
import { DemoThreeComponent } from './demos/demo-three/demo-three.component';
import { BlogComponent } from './demos/blog/blog.component';
import { BlogDetailsComponent } from './demos/blog-details/blog-details.component';

const routes: Routes = [
    { path: '', component: DefaultComponent },
    // { path: 'demo-two', component: DemoTwoComponent },
    // { path: 'demo-three', component: DemoThreeComponent },
    // { path: 'blog', component: BlogComponent },
    // { path: 'blog-details', component: BlogDetailsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
